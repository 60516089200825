body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    text-align: center;
}

.header {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    background: #333;
    color: white;
}

.header nav a {
    color: white;
    margin: 0 10px;
    text-decoration: none;
}

.container {
    padding: 20px;
}

.cta-button {
    background: #007bff;
    color: white;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    display: inline-block;
    margin-top: 20px;
}

.service-card {
    border: 1px solid #ddd;
    padding: 20px;
    margin: 10px;
    display: inline-block;
    width: 250px;
}

.footer {
    margin-top: 20px;
    padding: 10px;
    background: #333;
    color: white;
}
